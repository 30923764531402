import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import RedditIcon from '@material-ui/icons/Reddit';
import TwitterIcon from '@material-ui/icons/Twitter';

const Share = ({ socialConfig }) => (
  <div className="post-social">
    <FacebookShareButton
      url={socialConfig.config.url}
      style={{ padding: '2px' }}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <FacebookIcon style={{ color: '#3b5998' }} />
      </span>
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      style={{ padding: '2px' }}
    >
      <span className="icon">
        <TwitterIcon style={{ color: '#1da1f2' }} />
      </span>
    </TwitterShareButton>
    <LinkedinShareButton
      url={socialConfig.config.url}
      style={{ padding: '2px' }}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <LinkedInIcon style={{ color: '#0077b5' }} />
      </span>
    </LinkedinShareButton>
    <RedditShareButton
      url={socialConfig.config.url}
      style={{ padding: '2px' }}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <RedditIcon style={{ color: '#ff4500' }} />
      </span>
    </RedditShareButton>
    <WhatsappShareButton
      url={socialConfig.config.url}
      style={{ padding: '2px' }}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <WhatsAppIcon style={{ color: '#128c7e' }} />
      </span>
    </WhatsappShareButton>
  </div>
);

Share.propTypes = {
  socialConfig: PropTypes.shape({
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Share;
