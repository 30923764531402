/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Img from 'gatsby-image';
import { useNavigate } from '@reach/router';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Share from '../components/Share';

const useStyles = makeStyles({
  header: {
    marginTop: '70px',
  },
  info: {
    padding: '50px',
  },
  paper: {
    paddingTop: '100px',
    padding: '10px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

export default function Blog({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const classes = useStyles();
  const { mdx } = data; // data.mdx holds your post data
  const { frontmatter } = mdx;
  const post = data.mdx;
  const featuredImgFluid = mdx.frontmatter.featuredImage.childImageSharp.fluid;

  const navigate = useNavigate();

  const handleRoute = (href) => {
    navigate(href);
  };

  return (
    <section style={{ height: '100vh' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bhagat & Kumar | Blog</title>
        <meta
          property="og:title"
          content={`Bhagat & Kumar Blogs - ${frontmatter.title}`}
        />
        <meta
          property="og:url"
          content={`${process.env.SITE_URL}${frontmatter.path}`}
        />
        <meta property="og:description" content={frontmatter.desc} />
        <meta property="og:image" content={featuredImgFluid} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={featuredImgFluid} />
        <meta name="twitter:site" content="@bhagatandkumar" />
        <meta
          name="twitter:title"
          content={`Bhagat & Kumar Blogs - ${frontmatter.title}`}
        />
        <meta name="twitter:description" content={frontmatter.desc} />
      </Helmet>
      <Header />
      <section
        style={{ margin: 'auto', maxWidth: '1000px', marginBottom: '50px' }}
      >
        <Paper className={classes.paper} elevation={0}>
          <Button
            variant="text"
            color="primary"
            onClick={() => handleRoute('/useful-links')}
          >
            <ArrowBackIcon style={{ paddingRight: '8px' }} />
            Go Back to All Blogs
          </Button>
          <section style={{ float: 'right' }}>
            <Share
              socialConfig={{
                config: {
                  url: `${process.env.SITE_URL}${frontmatter.path}`,
                  title: `Bhagat & Kumar - ${frontmatter.title}`,
                },
              }}
            />
          </section>

          <h1>{frontmatter.title}</h1>
          <Img fluid={featuredImgFluid} />
          <br />
          <section style={{ textAlign: 'justify' }}>
            <MDXRenderer style={{ textAlign: 'justify' }}>
              {post.body}
            </MDXRenderer>
          </section>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleRoute('/useful-links')}
          >
            <ArrowBackIcon style={{ paddingRight: '8px' }} />
            Go Back to All Blogs
          </Button>
          {frontmatter.previous !== '' && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleRoute(frontmatter.previous)}
              style={{ float: 'right', marginRight: '10px' }}
            >
              Next
            </Button>
          )}
          {frontmatter.next !== '' && (
            <Button
              onClick={() => handleRoute(frontmatter.next)}
              variant="outlined"
              color="primary"
              style={{ float: 'right', marginRight: '10px' }}
            >
              Previous
            </Button>
          )}
        </Paper>
      </section>
      <Footer />
    </section>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        next
        desc
        previous
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
